.Footer-Component {
    @apply w-full px-[30px] md:px-[80px] lg:px-[120px] py-[50px] bg-background border-t border-solid;

    .Footer-Component-Content {
        @apply flex justify-between items-start flex-col md:flex-row lg:flex-row gap-[70px] md:gap-[20px] lg:gap-[20px] border-b border-solid pb-12;;

        .Footer-Section {
            @apply flex flex-col justify-start  items-start gap-[20px] md:gap-[20px] lg:gap-[20px];
        }
        .Address{
            @apply w-full  md:w-[30%] lg:w-[30%];

            h1{
                @apply text-[15px];
            }
            h2{
                @apply text-[15px];
            }
        }
        .Links{
            @apply w-full text-[15px] md:w-[15%] lg:w-[15%];
            h1{
                @apply text-[15px];
            }
            h2{
                @apply text-[15px];
            }
        }
        .Help{
            @apply w-full text-[15px] md:w-[15%] lg:w-[15%];
            h1{
                @apply text-[15px];
            }
            h2{
                @apply text-[15px];
            }
        }
        .Newsletter{
            @apply w-full text-[15px] md:w-[25%] lg:w-[25%];
            h1{
                @apply text-[15px];
            }
            h2{
                @apply text-[15px];
            }
        }
    }




}