.Home-Page{
  @apply flex justify-center items-center bg-background overflow-hidden flex-col gap-[60px] w-full ;
 .Home-Page-Content{
   @apply w-full px-[30px] md:px-[80px] lg:px-[120px] pb-[120px];

 }
  .section{
    @apply flex flex-col justify-center items-center gap-[60px];

    .section-information{
      @apply flex flex-col justify-center items-center gap-[20px];
    }
    .section-cart{
      @apply  w-full;
    }
 
  }

  .Home-Page-Options-Component{
    @apply w-full pl-[30px] md:pl-[80px] lg:pl-[120px] py-[20px] bg-secondary;
    .options-cart{
      @apply w-full;
    }
    .slider-component{
      @apply flex justify-center items-center sm:flex-row flex-col p-[20px,0px,20px,20px] gap-[50px];
  
      .section-component{
        @apply w-[100%] md:w-[50%] md:w-[50%];
      }
      .info-section{
        @apply flex flex-col gap-[20px];
      }
    }
  }
  
}