.NormalCart {
    @apply flex w-full justify-start items-center gap-[20px] flex-wrap ;
    
    .NormalCart-container {
        @apply flex w-full sm:w-full md:w-[32%] flex-col justify-center items-center gap-[20px] flex-wrap cursor-pointer;
        
        img {
            @apply object-cover sm:h-[600px] h-[350px] rounded-[5px];  // Optional, depending on how you want images to behave inside the container
            width: 100%;
        }

        h2 {
            @apply text-center font-semibold;
            font-size: calc(6px + 2vmin);
            font-weight: 500;
        }
    }
}
