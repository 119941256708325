.Onboarding-Page{
    @apply flex justify-center items-center p-[20px] bg-gray-100;
   
    .Onboarding-component-form{
        @apply bg-white shadow-md rounded-md p-6 w-full max-w-lg;

        .Onboarding-select-box
        {
            @apply flex justify-center;
            .active {
                @apply text-primary border-primary;
            }
            .notactive{
                @apply border-transparent text-primary_text;
            }
            .Onboarding-login-button{ 
                @apply flex-1 py-2 px-4  text-center border-b-2  font-semibold focus:outline-none;
            }
            .Onboarding-register-button{
                @apply flex-1 py-2 px-4  text-center border-b-2 font-semibold  focus:outline-none
            }
        }
        .form_label{
            @apply text-[15px] my-[10px] text-secondary_text;
        }
        .Onboarding-auth-box{
            @apply mt-6 space-y-4;

            .Onboarding-facebook-button{
                @apply w-full flex items-center justify-center space-x-2 bg-blue-600 text-white py-2 px-4 rounded-md focus:outline-none hover:bg-blue-700;
            }
            .Onboarding-google-button{
                @apply w-full flex items-center justify-center space-x-2 bg-red-600 text-white py-2 px-4 rounded-md focus:outline-none hover:bg-red-700
            }
        }
        .Onboarding-new-user{
            @apply mt-4 text-center text-sm text-gray-500;
            .Onboarding-create-account{
                @apply text-primary hover:underline focus:outline-none
            }
        }
    }
    .OTP-form{
        @apply flex flex-col justify-center p-[15%] items-center gap-[20px];
    }
}