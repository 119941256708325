@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
    @apply h-screen flex justify-center items-center bg-gray-100 overflow-hidden h-[100vh];
    font-family: 'Poppins', sans-serif;

    h1 {
        font-size: calc(6px + 2vmin);
        font-weight: 650;
    }

    h2 {
        font-size: calc(5px + 2vmin);
        font-weight: 600;
    }

    h3 {
        font-size: calc(3px + 2vmin);
        font-weight: 500;
    }
}