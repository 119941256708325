.Category-Page{
    @apply flex justify-center items-center bg-background flex-col gap-[60px] w-full ;
    
    .Category-banner{
        @apply sm:h-[450px] h-[350px] w-full;
    }
    .Category-flex-component {
      @apply flex gap-[40px] overflow-visible w-full py-[30px];
    
      .Category-section-filter {
        @apply hidden md:flex flex sticky flex-col justify-start items-start gap-[40px] w-[20%] h-[90vh] px-[10px] overflow-y-auto overflow-x-hidden top-[60px];
        scrollbar-width: thin;


      }
    
      .Category-section-information {
        @apply sm:w-[90%] w-[100%];
      }
      .Category-section-content {
        @apply w-[100%];
      }
    }
    
   .Category-Page-Content{
     @apply w-full px-[30px];
  
   }
    .Category-section{
      @apply flex flex-col justify-center items-center gap-[60px];

      .Category-section-with-sort{
        @apply flex items-center w-full ;

        .Category-Name-and-filter{
         @apply  flex sm:w-[77%] w-full sm:flex-row flex-col sm:gap-[0px] gap-[40px] justify-between sm:ml-[20%] ml-[0%];
         
         .Categroy-Filter{
          @apply w-[20%] hidden md:block;
         }
        }
      }
  
      .Category-section-information{
        @apply flex flex-col justify-center items-start gap-[20px];
      }
      .Category-section-cart{
        @apply  w-full;
      }
   
    }
      
  }