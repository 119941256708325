.custom-select {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: theme('colors.primary') !important; // Default border color (using Tailwind's primary color)
        color: theme('colors.primary_text') !important; // Text color (using Tailwind's primary text color)
  
        &:hover {
          border-color: theme('colors.primary') !important;
          color: theme('colors.primary_text') !important; // Hover border color
        }
  
        &.Mui-focused {
          border-color: theme('colors.primary') !important;
          color: theme('colors.primary_text') !important; // Focused border color
        }
      }
    }
  }
  