.filter-div{
    @apply  flex flex-col gap-5 border-b-2 border-gray-300 py-3;
    .checkbox-button {
        @apply text-primary !important;
    }
    .label {
        @apply text-primary_text !important;
    }

    .peer-checked + .MuiFormControlLabel-label {
        @apply text-primary !important; // Apply primary color to label when checkbox is checked
    }
    .button{
        @apply text-primary !important;
    }
}