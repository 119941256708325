.css-si86to-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: auto;
    min-height: 1.4375em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex !important;
    align-items: center !important;
}

.Table {
    .price-details {
        @apply text-[14px] font-[600] text-[#595353];
    }

    .price_off {
        @apply text-[#c71515];
    }
}