.Add-to-cart-page {
    @apply flex sm:flex-row flex-col justify-between items-start px-[4%] bg-[white];

    .Images-gallary {
        @apply sm:w-[50%] w-full;
    }

    .Product-details {
        @apply  sm:w-[50%] w-full flex justify-between items-center;
    }

    .Add-cart-page-button-group {
        @apply flex w-full sm:flex-row flex-col justify-start items-center gap-[10px] mt-[20px];

        .Counter {
            @apply sm:w-[16%] w-full;
        }

        .Add-button {
            @apply sm:w-[30%] w-full;
        }

    }
}

.Product-description {
    @apply bg-[white];
    border-top: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
    padding-top: 20px;

    .tabs_center {
        justify-content: center !important;
        // @apply flex justify-center items-center;
    }

    .css-1wxkzlj-MuiTabs-flexContainer {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center !important;
    }

    .css-1usuzwp-MuiButtonBase-root-MuiTab-root.Mui-selected {
        color: black;
        font-weight: 550;
    }

    .css-1qltlow-MuiTabs-indicator {
        background-color: transparent;
    }

    .css-1gsv261 {
        border-bottom: none;
        border-color: transparent;
    }
    .css-1gt725u {
        width: 100%;
        border-bottom: none;
        border-color: transparent;
    }
}

.Product-related {
    @apply flex flex-col gap-[40px] w-full justify-center bg-[white] items-center p-[20px];
    border-top: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);

    .Products-related-details {
        @apply w-[80%];
    }
    .Product-related-button{
        @apply sm:w-[20%] w-[80%];
    }
}