.SelectInput {
    h3 {
        @apply text-secondary_text text-[15px] my-[10px] ;
    }
    .MuiOutlinedInput-root {
        border-radius: 5px;
    
        .MuiOutlinedInput-notchedOutline {
          border-radius: 5px;
        }
    
        &:hover .MuiOutlinedInput-notchedOutline {
          border-color: gray; // Change border color on hover
        }
    
        &.Mui-focused .MuiOutlinedInput-notchedOutline {
          @apply border-primary // Set border color to red on focus
        }
      }
}