.Navbar-component{
    @apply bg-white text-black !important;
    .Navbar {
        @apply h-[70px];
    
         .Navbar-container {
            @apply flex-row justify-between  text-black;
        }
        .offset::hover{
            color: blue;
        }
    }
}
