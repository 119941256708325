.swiper {
  width: 100%;
  height: 80vh;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
  opacity: 0.9;
  object-fit: cover;
  height: 100%;
}

.Swiper_component {
  @apply static md:relative lg:relative w-full;

  .SwiperContent {
    @apply static md:absolute lg:absolute top-1/3 right-1 left-1 md:right-[5%] lg:right-[5%] md:left-auto lg:left-auto z-30 bg-secondary flex flex-col items-start gap-[15px] justify-center p-[20px] w-[auto] md:w-[35%] lg:w-[35%];

    .SwiperContent_title {
      @apply text-primary_text
    }

    .SwiperContent_tagline {
      @apply text-primary text-[35px] md:text-[52px] lg:text-[52px] font-[700] leading-tight;
    }

    .SwiperContent_description {
      @apply text-primary_text
    }
  }
}