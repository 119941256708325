.ProductCart {
    @apply flex justify-start items-center gap-[20px] flex-wrap;

    .ProductCart-container {
        @apply relative flex flex-col w-full sm:w-full md:w-[23%] justify-center items-center border-[1px] border-[#F4F5F7] bg-[#F4F5F7] opacity-100 gap-[20px] flex-wrap;

        img {
            height: 100%; /* Maintain the aspect ratio */
            width: 100%;  /* Ensure the image scales to the width of the container */
            max-height: 250px !important; /* Set the maximum height constraint */
            object-fit: cover; /* Optional: Adjust how the image is resized (cover, contain, etc.) */
        }        

        .ProductCart-text {
            @apply bg-[#F4F5F7] w-full p-[10px] min-h-[140px] flex flex-col items-start justify-start;

            h1 {
                font-size: calc(6px + 2vmin);
                font-weight: 650;
            }

            h2 {
                font-size: calc(5px + 2vmin);
                font-weight: 600;
            }

            h3 {
                font-size: calc(3px + 2vmin);
                font-weight: 500;
            }

            div {
                @apply flex w-full justify-start gap-[20px];
            }
        }

        .ProductCart-Discount {
            @apply absolute top-[3%] right-[5%] h-[50px] w-[50px] flex justify-center items-center rounded-full bg-[#E97171] text-white;
        }
    }

    .ProductCart-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: #3A3A3A;
        gap: 20px;
        opacity: 0;
    }

    .ProductCart-container:hover .ProductCart-overlay {
        opacity: 0.9;
        transition: opacity 0.5s ease; /* Adjust duration here */
    }
}
