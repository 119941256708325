.filter-div{
    @apply  flex flex-col gap-5 border-b-2 border-gray-300 py-3;
    .CheckBox_Size{
        @apply text-primary border-primary !important;
    }
    .label {
        @apply text-primary_text !important;
    }
    .button{
        @apply text-primary;
    }
}