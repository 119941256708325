.ProductCart-text {
    @apply flex flex-col justify-center items-start gap-[20px];

    h1 {
        font-size: calc(12px + 2vmin);
        font-weight: 600;
    }

    h2 {
        font-size: calc(12px + 1vmin);
        font-weight: 500;
    }

    h3 {
        font-size: calc(8px + 1vmin);
        font-weight: 500;
    }

    hr {
        background-color: rgba(0, 0, 0, 0.12);
    }

    .size-chart {
        @apply flex flex-col justify-center items-start;

        .CheckBox_Size {
            @apply text-primary border-primary;
        }

        .CheckBox_Size-active {
            @apply text-white bg-primary;
        }
        .color_box{
            @apply rounded-[50%] h-[35px] w-[35px] cursor-pointer;
        }
        .color_box-active{
            @apply rounded-[50%] h-[35px] w-[35px] cursor-pointer border-primary border-4;
        }
    }

    .color-chart {
        @apply flex flex-col justify-center items-start;
    }

    .Product-details {
        ul {
            font-size: calc(8px + 1vmin);
        }

        li {
            font-size: calc(8px + 1vmin);
        }
    }

    .Add-button {
        .button {
            @apply w-full text-[black] border-[black];
        }
    }
}