.Shopping_Cart_Page {
    @apply w-full justify-between flex sm:flex-row flex-col items-start h-full sm:px-[4%] px-[2%] py-[40px] sm:gap-[0px] gap-[10px];
    
    .cart_product_details {
        @apply sm:w-[69%] w-full h-full sm:p-[15px] p-[5px] rounded-[10px];

        h3 {
            font-size: calc(8px + 1vmin);
            font-weight: 500;
        }
    }

    .order_summary_details {
        @apply sm:w-[29%] w-full h-full sm:p-[15px] p-[5px] rounded-[10px];
    }
}
.empty_cart{
    @apply  justify-center sm:px-[4%] px-[4%];
}